body {
  font-family: "DejaVu Sans Mono", monospace;
  line-height: 1.6;
  margin: 0;
  width: 100%;
  background: rgb(245, 235, 228);
  height: auto;
}

button {
  background-color: transparent;
  margin-right: 5px;
}

.active {
  background-color: rgb(212, 134, 60);
}

.btn,
button {
  border: 1px solid #000;
  border-radius: 40px;
  padding: 5px 15px;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: background 500ms ease;
}

.btn:hover,
button:hover,
.btn.active {
  background: rgb(212, 134, 60);
  color: black;
}

a,
.footerLinks {
  text-decoration: none;
  color: #000;
  display: inline-block;
}

.footerLinks:hover {
  color: rgb(177, 96, 49);
  font-weight: bold;
}

#showcase,
#reason {
  width: 80%;
  margin: auto;
}

/* TEXT SIZES */
h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2rem;
}

.largeLetters {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(212, 134, 60);
}

/* SHOWCASE SECTION */
#titleName {
  font-family: "Brush Script MT", lucida, papyrus, cursive;
}

#showcase-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

#showcase-btn {
  padding-top: 2rem;
}

.showcaseImg {
  width: 200px;
  height: 200px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 50%;
}

/* ABOUT ME SECTION */
#about {
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(248, 244, 240);
}
.aboutText {
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 25px;
}
.aboutimg {
  border-radius: 10px;
  box-shadow: 2px 4px 4px 0 rgb(0, 0, 0, 0.4);
  width: 100%;
}
.aboutimages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.imgcontainer {
  position: relative;
  margin-right: 10px;
}
.overlay {
  display: flex;
  justify-content: center;
}
.show {
  display: none;
}
/* THE REASON SECTION */

#reason {
  height: auto;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

img {
  width: 200px;
}

#speechBubbleText {
  margin: 30px;
  text-align: center;
  color: #000;
}

.speech-bubble {
  width: 90%;
  height: auto;
  margin: 50px auto;
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  box-shadow: 2px 4px 4px 0 rgb(0, 0, 0, 0.4);
  position: relative;
}

.speech-bubble:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border: 1em solid #fff;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: -3px 7px 4px 0 rgba(0, 0, 0, 0.4);
}

/* FOOTER */
footer {
  background-color: #fff;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  position: relative;
}

.footerImg {
  width: auto;
  height: 400px;
  justify-content: center;
  margin-top: 30px;
  border-radius: 30px;
  box-shadow: 2px 4px 4px 0 rgb(0, 0, 0, 0.4);
}
.line {
  border-bottom: 1px solid black;
}

@media only screen and (min-width: 800px) {
  main {
    width: 100%;
  }
  #about,
  #reason,
  footer {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  button {
    margin-right: 20px;
  }
  #showcase-text {
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
  }
  .speech-bubble {
    width: 600px;
  }
  .footerContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: none;
  }
  .footerImg {
    width: auto;
    height: 500px;
    margin-right: 100px;
  }
  .footerText {
    display: flex;
    flex-direction: column;
  }
  .aboutText {
    padding-left: 25%;
    padding-right: 25%;
  }
  .aboutimages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
  }

  .aboutimg {
    display: block;
    width: 100%;
    border-radius: 10px;
  }
  .aboutimg:hover {
    opacity: 0.1;
    transition: opacity 0.4s ease-in;
    cursor: pointer;
  }
  .overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(224, 224, 224, 0.75);
    transition: 0.4s ease;
    border-radius: 10px;
  }
  .overlayText {
    color: rgb(68, 67, 67);
    font-size: 25px;
    font-weight: bold;
  }

  .overlay:hover {
    opacity: 1;
    transition: opacity 0.4s ease-in;
  }
  .overlayText:hover {
    opacity: 1;
    transition: opacity 0.4s ease-in;
  }
  .show {
    display: initial;
  }
  .acctButton {
    background-color: rgb(177, 96, 49);
    margin-right: 0;
  }
  .acctButton:hover {
    color: black;
    font-weight: bold;
  }
}
